import type {
  BaseCitationDetails,
  CitationDetails,
  MdCitationDetails,
  PDFCitationDetails,
  TxtCitationDetails,
} from "../../component/types";
import type { CitationDetailsDto } from "../dtos/citationDetailsDto";

export const fromDto = (
  citation: CitationDetailsDto,
): CitationDetails | null => {
  const baseCitationLink: BaseCitationDetails = {
    id: citation.id,
    type: citation.type,
    name: citation.name,
    content: citation.content,
  };

  switch (citation.type) {
    case "PDF":
      return pdfFromDto(baseCitationLink, citation);
    case "TEXT":
      return txtFromDto(baseCitationLink, citation);
    case "MD":
      return mdFromDto(baseCitationLink, citation);
    default: {
      // Ignore unsupported citation types for now.
      return null;
    }
  }
};

export const pdfFromDto = (
  baseCitationLink: BaseCitationDetails,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): PDFCitationDetails => {
  return {
    ...baseCitationLink,
    type: "PDF",
    name: citation.name,
    url: citation.preSignedUrl,
    highlightArea: {
      start: {
        x: citation.metadata.start.x,
        y: citation.metadata.start.y,
        pageNumber: citation.metadata.start.pageNumber,
      },
      end: {
        x: citation.metadata.end.x,
        y: citation.metadata.end.y,
        pageNumber: citation.metadata.end.pageNumber,
      },
    },
  };
};

export const txtFromDto = (
  baseCitationLink: BaseCitationDetails,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): TxtCitationDetails => {
  return {
    ...baseCitationLink,
    type: "TEXT",
    chunk: citation.content,
    url: citation.preSignedUrl,
  };
};

const mdFromDto = (
  baseCitationLink: BaseCitationDetails,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): MdCitationDetails => {
  return {
    ...baseCitationLink,
    type: "MD",
    url: citation.preSignedUrl,
  };
};
